import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import { MediaAccordion, List } from 'src/components';
import subpageNavigations from 'src/data/subpage-navigations';
import FileIcon from 'src/assets/icons/file.svg';

const MediaList = (media, subcategoryName, groupName) => {
  const listItems = [];
  media.map((file, index) => {
    const {
      title,
      localFile: { publicURL },
      acf: { subcategory, group }
    } = file.node;
    if (subcategory === subcategoryName && group === groupName) {
      listItems.push({
        key: index,
        text: title,
        href: publicURL,
        icon: FileIcon
      });
    }
    return null;
  });

  return (
    <div
      className={`${
        groupName ? 'pl-4 pr-12 md:pl-8 md:pr-16' : 'pr-12 md:pr-32'
      }`}
    >
      <List items={listItems} columns={1}></List>
    </div>
  );
};

const MediaCategoryPage = ({ data, pageContext }) => {
  const subcategoriesWithGroups = JSON.parse(
    JSON.stringify(data.subcategoriesWithGroups.group)
  );
  const media = data.media.edges;

  /**
   * Flatten nodes and remove duplicated groups
   */
  subcategoriesWithGroups.forEach(subcategoryWithGroups => {
    const groups = subcategoryWithGroups.nodes;
    const groupNames = [];

    groups.forEach(group => {
      if (group.acf !== undefined) {
        const groupName = group.acf.group;
        if (!groupNames.includes(groupName)) {
          groupNames.push(groupName);
        }
      }
    });

    subcategoryWithGroups.nodes = groupNames;
  });

  return (
    <MainLayout
      title={pageContext.category}
      headvisual={{
        image: data.headvisual,
        headline: pageContext.category,
        position: 'right'
      }}
      subpageNavItems={subpageNavigations.mediathek}
      useParentPathname
    >
      {subcategoriesWithGroups.map((subcategoryWithGroups, index) => (
        <section key={index}>
          <div className="container container--simple">
            <h2 className="mb-5 text-2xl">
              {subcategoryWithGroups.fieldValue}
            </h2>
            {/* files with no category */}
            {subcategoryWithGroups.nodes
              .filter(groupName => groupName === '')
              .map((groupName, index) => (
                <div key={index}>
                  {MediaList(
                    media,
                    subcategoryWithGroups.fieldValue,
                    groupName
                  )}
                </div>
              ))}

            {/* files with category */}
            {subcategoryWithGroups.nodes
              .filter(groupName => groupName !== '')
              .map((groupName, index) => (
                <MediaAccordion key={index} title={groupName}>
                  <div key={index}>
                    {MediaList(
                      media,
                      subcategoryWithGroups.fieldValue,
                      groupName
                    )}
                  </div>
                </MediaAccordion>
              ))}
          </div>
        </section>
      ))}
    </MainLayout>
  );
};

export default MediaCategoryPage;

export const query = graphql`
  query($category: String!) {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-behind-tablet-wide.jpg" }
    ) {
      ...image
    }
    subcategoriesWithGroups: allWordpressWpMedia(
      filter: { acf: { category: { eq: $category } } }
    ) {
      group(field: acf___subcategory) {
        fieldValue
        nodes {
          acf {
            group
          }
        }
      }
    }
    media: allWordpressWpMedia(
      filter: { acf: { category: { eq: $category } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          localFile {
            publicURL
          }
          acf {
            subcategory
            group
          }
        }
      }
    }
  }
`;
